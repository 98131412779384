import axios from 'axios'
import settings from '../settings/apis.js'

class LottoApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].lottoApiUrl,
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    getLotteriesForFilter() {
        return this.Api.get('/lotto/getLotteriesForFilters')
    }
    getCurrentOffer(startDate, endDate, lottoCode) {
        var postObj = {
            StartDateString: startDate,
            EndDateString: endDate,
            LotteryCode: lottoCode,
        };
        return this.Api.post('/lotto/getCurrentOffer', postObj)
    }
    getResults(startDate, endDate, lottoCode) {
        var postObj = {
            StartDateString: startDate,
            EndDateString: endDate,
            LotteryCode: lottoCode,
        };
        return this.Api.post('/results/getResultsBO', postObj)
    }
    getDrawResultsSettings(drawId) {
        return this.Api.get('/results/getDrawResultsSettings/' + drawId)
    }
    getTicketContent(ticketId) {
        return this.Api.get('/tickets/getTicketContent/' + ticketId)
    }
    getLotteriesDesc() {
        return this.Api.get('/lotto/getLotteriesDesc/true')
    }
}

export {LottoApi}
