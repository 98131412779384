<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ resultsSettings.name + ' - ' + resultsSettings.date }}</h5>
                <div class="p-col-12 p-grid">
                    <div class="card p-col-12 p-md-4 p-ml-2" v-for="(draw, index) in resultsSettings.draws" :key="'draw' + index">
                        <h5>{{ draw.drawName }} Numbers</h5>
                        <div class="p-col-12 p-grid">
                            <div class="p-col-4 p-md-2" v-for="(number, index) in resultsSettings.drawNo" :key="'no' + index + draw">
                                <InputText class="p-text-center" style="width: 100%" :placeholder="(index + 1).toString()" type="text" v-model="draw.drawNo[index]" />
                            </div>
                        </div>
                    </div>
                    <div class="card p-col-6 p-md-2 p-ml-2" v-show="resultsSettings.extraNo.count > 0">
                        <h5>Extra Numbers</h5>
                        <div class="p-col-12 p-grid">
                            <div class="p-col-4 p-md-4" v-for="(number, index) in resultsSettings.extraNo.count" :key="'noExtra' + index">
                                <InputText class="p-text-center" style="width: 100%" :placeholder="(index + 1).toString()" type="text" v-model="resultsSettings.extraNo.no[index]" />
                            </div>
                        </div>
                    </div>

                    <transition-group name="p-message" tag="div" v-show="showErrorMessage" class="p-col-12 p-mb-2">
                        <Message :key="errorKeyCount" :severity="'error'">{{ errorMessage }}</Message>
                    </transition-group>
                    <div class="p-col-12">
                        <Button @click="addResults()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                        <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto('lottoResults')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { BoLottoApi } from '../../service/BoLottoApi';
import { LottoApi } from '../../service/LottoApi';
export default {
    name: 'LottoResults',
    data() {
        return {
            resultsSettings: {
                extraNo: { count: 0, no: [] },
                draws: [],
                maxNo: 0,
                drawNo: 0,
                name: '',
                date: '',
                drawId: '',
            },
            LottoApi: new LottoApi(),
            BoLottoApi: new BoLottoApi(),
            showErrorMessage: false,
            errorMessage: '',
            errorKeyCount: 0,
        };
    },
    computed: {},
    mounted() {
        this.getResultsSettings(this.$route.params.id);
    },
    methods: {
        getResultsSettings(drawId) {
            this.LottoApi.getDrawResultsSettings(drawId).then((response) => {
                this.resultsSettings.extraNo.count = response.data.extraNoCount;
                this.resultsSettings.name = response.data.lotteryName;
                this.resultsSettings.maxNo = response.data.maxNo;
                this.resultsSettings.drawNo = response.data.drawNo;
                this.resultsSettings.drawId = response.data.drawId;
                this.resultsSettings.date = this.formatCSDateAndTime(response.data.date);
                if (response.data.results != null) {
                    this.resultsSettings.draws = response.data.results.draws;
                    if (response.data.results.extraNo != null) {
                        for (let i = 0; i < response.data.results.extraNo.length; i++) {
                            this.resultsSettings.extraNo.no.push(response.data.results.extraNo[i].number);
                        }
                    }
                } else {
                    for (let i = 0; i < response.data.draws.length; i++) {
                        if (response.data.draws[i] != 'DOUBLE_DRAW') {
                            var drawObj = {
                                drawName: response.data.draws[i],
                                drawNo: [],
                            };
                            this.resultsSettings.draws.push(drawObj);
                        }
                    }
                }
            });
        },
        showError(errorText) {
            this.showErrorMessage = true;
            this.errorKeyCount++;
            this.errorMessage = errorText;
        },
        addResults() {
            const empty = (element) => element === '';
            const validNumber = (element) => parseInt(element) > parseInt(this.resultsSettings.maxNo);
            this.showErrorMessage = false;
            this.errorMessage = '';
            if (this.resultsSettings.extraNo.count > 0) {
                if (this.resultsSettings.extraNo.no.length != this.resultsSettings.extraNo.count || this.resultsSettings.extraNo.no.some(empty)) {
                    this.showError(this.$t('lotto.alerts.extraNoMandatory'));
                }
                if (this.resultsSettings.extraNo.no.some(isNaN)) {
                    this.showError(this.$t('lotto.alerts.onlyNumbers'));
                }
                if (this.resultsSettings.extraNo.no.some(validNumber)) {
                    this.showError(this.$t('lotto.alerts.extraNoMax') + this.resultsSettings.maxNo);
                }
                let uniqueValues = [...new Set(this.resultsSettings.extraNo.no)];
                if (uniqueValues.length != this.resultsSettings.extraNo.no.length) {
                    this.showError(this.$t('lotto.alerts.extraNoDuplicates'));
                }
            }
            if (!this.showErrorMessage) {
                for (var i = 0; i < this.resultsSettings.draws.length; i++) {
                    if (this.resultsSettings.draws[i].drawNo.length != this.resultsSettings.drawNo || this.resultsSettings.draws[i].drawNo.some(empty)) {
                        this.showError(this.$t('lotto.alerts.allFields'));
                    }
                    if (this.resultsSettings.draws[i].drawNo.some(isNaN)) {
                        this.showError(this.$t('lotto.alerts.onlyNumbers'));
                    }
                    if (this.resultsSettings.draws[i].drawNo.some(validNumber)) {
                        this.showError(this.$t('lotto.alerts.maxLottery') + this.resultsSettings.maxNo);
                    }
                    /*   let uniqueValues = [...new Set(this.resultsSettings.draws[i].drawNo)];
                    if (
                        uniqueValues.length != this.resultsSettings.draws[i].drawNo.length
                    ) {
                        this.showErrorMessage = true;
                        this.errorMessage = this.$t("lotto.alerts.duplicates");
                    }*/
                }
            }
            if (!this.showErrorMessage) {
                this.BoLottoApi.addLottoResults(this.resultsSettings)
                    .then((response) => {
                        if (response.data.status) {
                            this.goto('lottoResults');
                        } else {
                            this.showError(this.response.data.message);
                        }
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showError(this.$t('alerts.generalError'));
                    });
            }
        },
    },
};
</script>
