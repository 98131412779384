import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoLottoApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl,
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    blockDraw(drawId, status) {
        return this.Api.get('/lotto/blockDraw/' + drawId + '/' + status);
    }
    processLottoTickets(drawId) {
        return this.Api.get('/lotto/processTickets/' + drawId);
    }
    publishLottoOffer(requestObj) {
        return this.Api.post('/lotto/publishOffer', requestObj)
    }
    addLottoResults(resultsSettings) {
        var extraNo = [];
        var drawResults = [];
        if (resultsSettings.extraNo.count > 0) {
            for (let i = 0; i < resultsSettings.extraNo.count; i++) {
                var extraNoObj = {ExtraId: i + 1, Number: resultsSettings.extraNo.no[i]};
                extraNo.push(extraNoObj);
            }
        }
        for (let i = 0; i < resultsSettings.draws.length; i++) {
            var drawNoObj = {DrawName: resultsSettings.draws[i].drawName, DrawNo: resultsSettings.draws[i].drawNo};
            drawResults.push(drawNoObj);
        }
        var postObj = {
            DrawId: resultsSettings.drawId,
            AddedBy: "",
            ExtraNumbers: extraNo,
            DrawResults: drawResults
        };
        return this.Api.post('/lotto/addResults', postObj)
    }
}

export {
    BoLottoApi
}
